<template>
  <b-card>
    <div class="row m-1">
      <div class="col-xl-6 col-lg-12 d-flex flex-column justify-content-between border-container-lg">
        <div class="user-avatar-section">
          <div class="d-flex justify-content-start">
            <img 
              :src="previewImage" 
              width="104"
              height="104"
              class="img-fluid rounded mr-1" 
            >
            <div class="d-flex flex-column ms-1">
              <div class="user-info mb-1">
                <h4 class="m-0">
                  {{ datas.name }}
                </h4>
                <span class="card-text">{{ datas.code }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 mt-2 mt-xl-0">
        <div class="user-info-wrapper">
          <p>
            <feather-icon
              icon="CompassIcon"
            /><span class="card-text user-info-title fw-bold ml-1">Region : <span
              v-if="datas.service_area != null && datas.service_area.region"
              v-text="datas.service_area.region.name"
            /></span>
            <br>
            <feather-icon
              icon="CompassIcon"
            /><span class="card-text user-info-title fw-bold ml-1">Area Layanan : <span
              v-if="datas.service_area != null"
              v-text="datas.service_area.name"
            /></span>
            <br>
            <feather-icon
              icon="CompassIcon"
            /><span class="card-text user-info-title fw-bold ml-1">{{ datas.address }}</span>
            <br>
            <feather-icon
              icon="UserIcon"
            /><span class="card-text user-info-title ml-1">Pimpinan : <span
              v-if="datas.leader != null"
              v-text="datas.leader.name"
            /></span>
            <br>
            <feather-icon
              icon="UserIcon"
            /><span class="card-text user-info-title ml-1">PIC : <span
              v-if="datas.pic != null"
              v-text="datas.pic.name"
            /></span>
            <br>
          </p>
        </div>
      </div>
    </div>
    <hr class="ml-1">
    <div class="row m-1">
      <div class="col-lg-12">
        <button
          class="btn btn-info"
          @click="kembali()"
        >
          <feather-icon
            icon="ChevronsLeftIcon"
          />
        </button>&nbsp;
        <button
          class="btn btn-primary"
          @click="editForm(datas.id)"
        >
          Edit
        </button>&nbsp;
        <button
          class="btn btn-outline-danger"
          @click="confirmDelete(datas.id,datas.name)"
        >
          Hapus
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-6 col-12">
        <div class="card bg-light-primary rounded">
          <div class="card-header">
            <div>
              <h2 class="mb-0">
                <b>120</b>
              </h2>
              <p class="card-text">
                Kendaraan Aktif
              </p>
            </div>
            <div class="avatar bg-light-success p-50 m-0">
              <div class="avatar-content">
                <feather-icon
                  icon="TruckIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 col-12">
        <div class="card bg-light-primary rounded">
          <div class="card-header">
            <div>
              <h2 class="mb-0">
                <b>45 Km</b>
              </h2>
              <p class="card-text">
                Rata-rata jarak tempuh harian
              </p>
            </div>
            <div class="avatar bg-light-danger p-50 m-0">
              <div class="avatar-content">
                <feather-icon
                  icon="ActivityIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {   
      previewImage:require('@/assets/images/avatars/logo_Avatar.png'),
      datas:{},
      params:null,
      loading:false,
    }
  },
  mounted() {
      this.getData()
  },
  methods: {
    getData(){
      this.loading =true
        this.$http.get('/service-center/'+this.$route.params.id)
        .then(res  => {
                this.datas = res.data
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false) 
    },
    kembali(){
      this.$router.push({ name:'layanan'})
    },
    editForm(id){
      this.$router.push({ name:'layanan-edit',params : { id: id} })
    },
    confirmDelete(id,name){
      this.$bvModal
        .msgBoxConfirm('Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain', {
          title: 'Konfirmasi',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Hapus',
          cancelTitle: 'Tutup',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete('/service-center/'+id)
              .then(() => {
                this.$router.push({ name:'layanan' })
            })
          }
        })
    }
  }
}
</script>